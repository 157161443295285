import React from "react";
import Characters from '../components/Charakters/Characters'

function Character() {
  return <div>
      <Characters />
  </div>;
}

export default Character;
