import React from 'react'
import Hero from '../components/Utama/Hero'
import Randoms from '../components/Randoms/Randoms'

function Home() {
  return (
    <div>
      <Hero />
      <Randoms />
    </div>
  )
}

export default Home