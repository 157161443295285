import React from "react";
import hero from "./img/maxresdefault-3175185557.jpg";

function Hero() {
  return (
    <div className="hero">
      <img src={hero} alt="" />
    </div>
  );
}

export default Hero;
