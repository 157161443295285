import React from "react";
import Details from "../components/Details/Details";

function Detail() {
  return (
    <div>
      <Details />
    </div>
  );
}

export default Detail;
