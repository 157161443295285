import React from "react";
import Schools from "../components/Schools/Schools";

function Student() {
  return <div>
    <Schools />
  </div>;
}

export default Student;
