import React from "react";
import "./style.css";
import NavbarHamburger from "./NavMobile";

function Header() {
  return (
    <header>
        <NavbarHamburger />
    </header>
  );
}

export default Header;
